import Image1 from "../../assets/testimonial1.png";
import Image2 from "../../assets/testimonial2.png";
import Image3 from "../../assets/testimonial3.png";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Sam Hay",
    description:
      "I highly recommend Sid as a software engineer. His responsibility, reliability, and level-headedness make him an asset to any team. His front-end development skills are top-notch and I have no doubt that he will continue to excel in his career. I would encourage anyone considering working with or hiring Sid to do so.",
  },
  {
    id: 2,
    image: Image2,
    title: "Vy Nguyen",
    description:
      "I highly recommend Sid as a Software Engineer and for all of his future endeavors. During our time together in a General Assembly cohort, I was consistently impressed with Sid's exceptional creativity, outstanding work ethic, and deep understanding of front-end development. His skills in this area are truly remarkable, and he was always ahead of the game in terms of learning new technologies and techniques.",
  },
  {
    id: 3,
    image: Image3,
    title: "Zack Fiddler",
    description:
      "I had the pleasure of studying alongside Sid at General Assembly and I can confidently say that he is a highly reliable and talented software engineer. He consistently demonstrates strong time management skills and the ability to meet tight deadlines. Sid's front-end skills are impressive, he is able to deliver high quality, user-friendly interfaces. He is also a team player, always willing to lend a helping hand and share his knowledge with others. On top of his technical abilities, Sid also displays strong leadership skills and effectively took on project management roles ensuring the success of our team.",
  },
];
