import Work1 from "../../assets/work1.jpg";
// import Work2 from "../../assets/work2.jpg";
import Work3 from "../../assets/work3.jpg";
import Work4 from "../../assets/work4.jpg";
import Work5 from "../../assets/work5.jpg";

export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "2000s Memory Game",
    category: "BootCamp Projects",
    url:"https://github.com/SiddharthNand/2000s-memory-game",
    demo:"https://siddharthnand.github.io/2000s-memory-game/",
    lang: "HTML/CSS/Vanilla JavaScript",
    description:"Web application that recreates the experience of the card game Memory for users. Allows for single or multiplayer.",

  },
  {
    id: 2,
    image: Work3,
    title: "Sid-E AI Image Generator",
    category: "Personal Projects",
    url:"https://github.com/SiddharthNand/SId-E-Clone",
    demo:"https://sidd-e.com/",
    lang: "MERN Stack Application",
    description:"An artistic image generator using OpenAI API, modeled after Dall-E, which creates images based on prompts provided by the user.",
  },
  {
    id: 3,
    image: Work4,
    title: "Fitness App (Yelp Clone)",
    category: "BootCamp Projects",
    url:"https://github.com/Boolean-Hooligans-DjangoApp/Boolean-Fitness-",
    demo:"https://shrouded-retreat-81056.herokuapp.com/",
    lang: "Django Python Application",
    description:"Boolean Fitness is a web application that allows users to find and review fitness studios and gyms based on location and activity type, and allows to advertise activities with the option of leaving ratings and reviews.",
  },
  {
    id: 4,
    image: Work5,
    title: "Linktree Clone",
    category: "Personal Projects",
    url:"https://github.com/SiddharthNand/Linktree-Clone",
    demo:"https://sidharthnand.dev/Linktree-Clone/",
    lang: "HTML/CSS/Vanilla JavaScript",
    description:"This project is a clone of the popular platform, Linktree, created using JavaScript, HTML, and CSS. It allows users to create a personalized landing page with links to their various online platforms such as social media and personal website.",
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "Personal Projects",
  },
  {
    name: "Bootcamp Projects",
  },
  // {
  //   name: "design",
  // },
];
