import React from "react";

const WorkItems = ({ item }) => {
  return (
    <div className="work__card" key={item.id}>
      <img src={item.image} alt="" className="work__img" />
      <h3 className="work__title">{item.title}</h3>
      <h4 className="workLang">{item.lang}</h4>
      <br></br>
      <p className="description">{item.description}</p>
      <br></br>
      <a href={item.url} target="_blank"
    rel="noreferrer" className="work__button">
       Github
        <i className="bx bx-right-arrow-alt work__button-icon"></i>
      </a>
      <a href={item.demo} target="_blank"
    rel="noreferrer" className="work__button">
       Demo
        <i className="bx bx-right-arrow-alt work__button-icon"></i>
      </a>
    </div>
  );
};

export default WorkItems;
